import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
export default function ColumnChart({ data, maxRange, rotateXLabels = false, xAxisTitle, yAxisTitle, ...rest }) {
    if (!data.length)
        return;
    let range;
    if (maxRange === undefined) {
        const maxY = Math.max(...data.map(({ y }) => y).filter((y) => y !== undefined));
        range = [0, maxY <= 7 ? Math.ceil(maxY + 1) : Math.ceil(maxY / 10) * 10];
    }
    else
        range = [0, maxRange];
    const yLabels = range[1] <= 10
        ? [...Array(range[1] + 1).keys()]
        : [...Array(11).keys()].map((n) => Math.round((n / 10) * range[1]));
    return (_jsxs("div", { ...rest, className: "column-chart", style: { "--column-count": data.length }, children: [_jsx("div", { className: "column-chart__grid-lines", children: yLabels.slice(1).map((x) => (_jsx("div", {}, x))) }), _jsx("div", { className: "column-chart__y-title fade-in nowrap", children: yAxisTitle }), _jsx("div", { className: "column-chart__x-title fade-in nowrap", children: xAxisTitle }), _jsx("div", { className: "column-chart__x-label" }), _jsx("div", { className: "column-chart__y-axis", style: { "--y-label-count": yLabels.length }, children: yLabels.map((yLabel, i) => (_jsx("div", { className: "column-chart__y-label fade-in", style: { "--y-label-number": i }, children: yLabel }, yLabel))) }), _jsx("div", { className: "column-chart__x-label" }), data.map(({ color, key, label, onClick, title, y }, i) => {
                return (_jsxs(Fragment, { children: [_jsx("div", { className: `column-chart__column${onClick ? " column-chart__column--clickable" : ""}`, onClick: onClick, title: title, style: {
                                "--column-height": `${(100 * (y ?? 0)) / range[1]}%`,
                                "--column-number": i,
                                color: 
                                // This is a hack to fix a bug that seems specific to Chrome
                                // where the bar appears visible during the rendering animation
                                y === undefined
                                    ? "transparent"
                                    : (color ?? "var(--color-theme)"),
                            } }), _jsx("div", { className: `column-chart__x-label fade-in${rotateXLabels ? " column-chart__x-label--rotate" : ""}`, style: {
                                "--x-label-number": i,
                            }, title: title, children: label })] }, key));
            })] }));
}
