import { jsx as _jsx } from "react/jsx-runtime";
import CardGroup from "./CardGroup";
export default function Card({ color, ...rest }) {
    const props = color
        ? Object.assign(rest, { style: { "--color": color } })
        : rest;
    if (!props.onClick)
        return (_jsx("div", { ...props, className: "card br-1 bw-1 p-3" }));
    return (_jsx("button", { ...props, "aria-label": "View card", className: "card br-1 bw-1 p-3 ripple", type: "button" }));
}
Card.Group = CardGroup;
