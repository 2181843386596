import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import Icon from "../../Icon";
export default function NavSubList({ heading, ...rest }) {
    const [isExpanded, setIsExpanded] = React.useState(true);
    const [hasBeenClicked, setHasBeenClicked] = React.useState(false);
    return (_jsxs(_Fragment, { children: [_jsx("li", { className: "nav-link m-0", children: _jsxs("button", { className: "nav-sub-list__heading fw-b ripple", onClick: () => {
                        if (!hasBeenClicked)
                            setHasBeenClicked(true);
                        setIsExpanded(!isExpanded);
                    }, children: [heading, _jsx("span", { className: `nav-sub-list__icon${isExpanded ? " nav-sub-list__icon--expanded" : ""}`, children: _jsx(Icon, { name: "down", strokeWidth: "4" }) })] }) }), _jsx("li", { className: "nav-sub-list__list", children: isExpanded && (_jsx("ul", { ...rest, className: `m-0 p-0${hasBeenClicked ? " slide-in" : ""}` })) })] }));
}
NavSubList.displayName = "Nav.SubList";
