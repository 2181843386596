import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
export const iconNameMap = {
    "at-sign": (_jsxs(_Fragment, { children: [_jsx("circle", { cx: "12", cy: "12", r: "4" }), _jsx("path", { d: "M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94" })] })),
    bell: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9" }), _jsx("path", { d: "M13.73 21a2 2 0 0 1-3.46 0" })] })),
    book: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M4 19.5A2.5 2.5 0 0 1 6.5 17H20" }), _jsx("path", { d: "M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z" })] })),
    chart: (_jsxs(_Fragment, { children: [_jsx("line", { x1: "18", y1: "20", x2: "18", y2: "10" }), _jsx("line", { x1: "12", y1: "20", x2: "12", y2: "4" }), _jsx("line", { x1: "6", y1: "20", x2: "6", y2: "14" })] })),
    check: _jsx("polyline", { points: "20 6 9 17 4 12" }),
    cloud: _jsx("path", { d: "M18 10h-1.26A8 8 0 1 0 9 20h9a5 5 0 0 0 0-10z" }),
    compass: (_jsxs(_Fragment, { children: [_jsx("circle", { cx: "12", cy: "12", r: "10" }), _jsx("polygon", { points: "16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76" })] })),
    copy: (_jsxs(_Fragment, { children: [_jsx("rect", { x: "9", y: "9", width: "13", height: "13", rx: "2", ry: "2" }), _jsx("path", { d: "M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" })] })),
    cross: (_jsxs(_Fragment, { children: [_jsx("line", { x1: "18", y1: "6", x2: "6", y2: "18" }), _jsx("line", { x1: "6", y1: "6", x2: "18", y2: "18" })] })),
    "dew-point": (_jsxs(_Fragment, { children: [_jsx("path", { d: "M5 12a5 5 0 106 0M5 12V3h6v9M11 3h2M11 6h2M11 9h2" }), _jsx("path", { d: "M8 14a2 2 0 100 4 2 2 0 000-4zm0 0V9" }), _jsx("path", { clipRule: "evenodd", d: "M19 3s3 2.993 3 4.887c0 1.655-1.345 3-3 3-1.656 0-2.988-1.345-3-3C16.01 5.992 19 3 19 3z" })] })),
    down: _jsx("polyline", { points: "6 9 12 15 18 9" }),
    download: (_jsxs(_Fragment, { children: [_jsx("polyline", { points: "8 17 12 21 16 17" }), _jsx("line", { x1: "12", y1: "12", x2: "12", y2: "21" }), _jsx("path", { d: "M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29" })] })),
    drizzle: (_jsxs(_Fragment, { children: [_jsx("line", { x1: "8", y1: "19", x2: "8", y2: "21" }), _jsx("line", { x1: "8", y1: "13", x2: "8", y2: "15" }), _jsx("line", { x1: "16", y1: "19", x2: "16", y2: "21" }), _jsx("line", { x1: "16", y1: "13", x2: "16", y2: "15" }), _jsx("line", { x1: "12", y1: "21", x2: "12", y2: "23" }), _jsx("line", { x1: "12", y1: "15", x2: "12", y2: "17" }), _jsx("path", { d: "M20 16.58A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25" })] })),
    droplet: _jsx("path", { d: "M12 2.69l5.66 5.66a8 8 0 1 1-11.31 0z" }),
    eye: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" }), _jsx("circle", { cx: "12", cy: "12", r: "3" })] })),
    fog: (_jsx("path", { d: "M9 14h6M9 22h6M7 18h10M3.5 17.382C2.188 16.707 1 15.388 1 13c0-4 3.333-5 5-5 0-2 0-6 6-6s6 4 6 6c1.667 0 5 1 5 5 0 2.388-1.188 3.707-2.5 4.382" })),
    heart: (_jsx("path", { d: "M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" })),
    help: (_jsxs(_Fragment, { children: [_jsx("circle", { cx: "12", cy: "12", r: "10" }), _jsx("path", { d: "M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" }), _jsx("line", { x1: "12", y1: "17", x2: "12.01", y2: "17" })] })),
    home: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" }), _jsx("polyline", { points: "9 22 9 12 15 12 15 22" })] })),
    key: (_jsx("path", { d: "M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4" })),
    left: _jsx("polyline", { points: "15 18 9 12 15 6" }),
    lightning: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M19 16.9A5 5 0 0 0 18 7h-1.26a8 8 0 1 0-11.62 9" }), _jsx("polyline", { points: "13 11 9 17 15 17 11 23" })] })),
    link: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" }), _jsx("path", { d: "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" })] })),
    location: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" }), _jsx("circle", { cx: "12", cy: "10", r: "3" })] })),
    lock: (_jsxs(_Fragment, { children: [_jsx("rect", { x: "3", y: "11", width: "18", height: "11", rx: "2", ry: "2" }), _jsx("path", { d: "M7 11V7a5 5 0 0 1 10 0v4" })] })),
    menu: (_jsxs(_Fragment, { children: [_jsx("line", { x1: "3", y1: "12", x2: "21", y2: "12" }), _jsx("line", { x1: "3", y1: "6", x2: "21", y2: "6" }), _jsx("line", { x1: "3", y1: "18", x2: "21", y2: "18" })] })),
    minus: _jsx("line", { x1: "5", y1: "12", x2: "19", y2: "12" }),
    moon: _jsx("path", { d: "M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" }),
    // From material design
    "partly-cloudy-day": (_jsx("svg", { viewBox: "0 0 48 48", children: _jsx("path", { d: "M23.15 11.25v-6.9h1.75v6.9Zm10.45 4.4-1.25-1.3 4.9-4.9 1.25 1.3Zm3.15 9.2V23.1h6.9v1.75Zm.5 13.65-4.85-4.9 1.3-1.3 4.85 4.9Zm-22.8-22.8-5-5 1.3-1.2 4.95 4.95Zm-.95 22.25h8q1.65 0 2.8-1.125T25.45 34q0-1.65-1.1-2.8t-2.8-1.15h-2l-.8-1.8q-.8-1.65-2.325-2.675Q14.9 24.55 13 24.55q-2.7 0-4.575 1.85T6.55 31q0 2.95 2 4.95t4.95 2Zm0 1.75q-3.6 0-6.15-2.55Q4.8 34.6 4.8 31q0-3.45 2.375-5.825T13 22.8q2.6 0 4.7 1.525t3 3.975q2.45 0 4.45 1.35t2 4.3q-.1 2.35-1.675 4.05-1.575 1.7-3.975 1.7Zm13.55-6.75q-.15-.35-.3-.75l-.3-.8q2.35-.8 3.775-2.85Q31.65 26.5 31.65 24q0-3.15-2.25-5.4-2.25-2.25-5.4-2.25-3.1 0-5.35 2.075Q16.4 20.5 16.3 23.6q-.35-.15-.825-.275Q15 23.2 14.65 23q.4-3.6 3.075-6Q20.4 14.6 24 14.6q3.9 0 6.65 2.75Q33.4 20.1 33.4 24q0 3.05-1.75 5.475t-4.6 3.475Z" }) })),
    // From material design
    "partly-cloudy-night": (_jsx("svg", { viewBox: "0 0 48 48", children: _jsx("path", { d: "M25.2 26.65Zm1 17.05h-5.65l.025-.225q.025-.225 0-.6t0-.625L20.6 42h4.75q4.75.25 8.675-2.15 3.925-2.4 6.175-6.1-4.25-.45-8.1-2.225-3.85-1.775-6.85-4.875-3.1-3-4.875-6.825T18.2 11.8q-3.75 2.2-5.95 5.85-2.2 3.65-2.2 7.95 0 .9.075 1.775.075.875.225 1.675l-.275.075q-.275.075-.575.1-.3.025-.575.05l-.275.025q-.15-.8-.25-1.75t-.1-1.75q0-5.65 3.15-10.175t8.4-6.475q.05 4.65 1.675 8.9t4.925 7.5q3.25 3.3 7.5 4.9 4.25 1.6 8.9 1.65-1.95 5.25-6.475 8.425Q31.85 43.7 26.2 43.7ZM12 41.95h9q1.25 0 2.125-.85T24 39.05q0-1.25-.825-2.15-.825-.9-2.075-.9h-2.65l-1-2.4q-.7-1.6-2.2-2.6-1.5-1-3.25-1-2.5 0-4.225 1.75Q6.05 33.5 6.05 36t1.725 4.225Q9.5 41.95 12 41.95Zm0 1.75q-3.25-.1-5.475-2.3Q4.3 39.2 4.3 36q0-3.25 2.225-5.5T12 28.25q2.3 0 4.175 1.25t2.875 3.3l.65 1.5h1.6q1.85.05 3.15 1.425 1.3 1.375 1.3 3.275 0 2-1.375 3.35T21 43.7Z" }) })),
    pause: (_jsxs(_Fragment, { children: [_jsx("rect", { x: "6", y: "4", width: "4", height: "16" }), _jsx("rect", { x: "14", y: "4", width: "4", height: "16" })] })),
    play: _jsx("polygon", { points: "5 3 19 12 5 21 5 3" }),
    plus: (_jsxs(_Fragment, { children: [_jsx("line", { x1: "12", y1: "5", x2: "12", y2: "19" }), _jsx("line", { x1: "5", y1: "12", x2: "19", y2: "12" })] })),
    rain: (_jsxs(_Fragment, { children: [_jsx("line", { x1: "16", y1: "13", x2: "16", y2: "21" }), _jsx("line", { x1: "8", y1: "13", x2: "8", y2: "21" }), _jsx("line", { x1: "12", y1: "15", x2: "12", y2: "23" }), _jsx("path", { d: "M20 16.58A5 5 0 0 0 18 7h-1.26A8 8 0 1 0 4 15.25" })] })),
    right: _jsx("polyline", { points: "9 18 15 12 9 6" }),
    save: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" }), _jsx("polyline", { points: "17 21 17 13 7 13 7 21" }), _jsx("polyline", { points: "7 3 7 8 15 8" })] })),
    settings: (_jsxs(_Fragment, { children: [_jsx("circle", { cx: "12", cy: "12", r: "3" }), _jsx("path", { d: "M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z" })] })),
    share: (_jsxs(_Fragment, { children: [_jsx("circle", { cx: "18", cy: "5", r: "3" }), _jsx("circle", { cx: "6", cy: "12", r: "3" }), _jsx("circle", { cx: "18", cy: "19", r: "3" }), _jsx("line", { x1: "8.59", y1: "13.51", x2: "15.42", y2: "17.49" }), _jsx("line", { x1: "15.41", y1: "6.51", x2: "8.59", y2: "10.49" })] })),
    "sign-out": (_jsxs(_Fragment, { children: [_jsx("path", { d: "M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" }), _jsx("polyline", { points: "16 17 21 12 16 7" }), _jsx("line", { x1: "21", y1: "12", x2: "9", y2: "12" })] })),
    snow: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M20 17.58A5 5 0 0 0 18 8h-1.26A8 8 0 1 0 4 16.25" }), _jsx("line", { x1: "8", y1: "16", x2: "8.01", y2: "16" }), _jsx("line", { x1: "8", y1: "20", x2: "8.01", y2: "20" }), _jsx("line", { x1: "12", y1: "18", x2: "12.01", y2: "18" }), _jsx("line", { x1: "12", y1: "22", x2: "12.01", y2: "22" }), _jsx("line", { x1: "16", y1: "16", x2: "16.01", y2: "16" }), _jsx("line", { x1: "16", y1: "20", x2: "16.01", y2: "20" })] })),
    sun: (_jsxs(_Fragment, { children: [_jsx("circle", { cx: "12", cy: "12", r: "5" }), _jsx("line", { x1: "12", y1: "1", x2: "12", y2: "3" }), _jsx("line", { x1: "12", y1: "21", x2: "12", y2: "23" }), _jsx("line", { x1: "4.22", y1: "4.22", x2: "5.64", y2: "5.64" }), _jsx("line", { x1: "18.36", y1: "18.36", x2: "19.78", y2: "19.78" }), _jsx("line", { x1: "1", y1: "12", x2: "3", y2: "12" }), _jsx("line", { x1: "21", y1: "12", x2: "23", y2: "12" }), _jsx("line", { x1: "4.22", y1: "19.78", x2: "5.64", y2: "18.36" }), _jsx("line", { x1: "18.36", y1: "5.64", x2: "19.78", y2: "4.22" })] })),
    sunrise: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M17 18a5 5 0 0 0-10 0" }), _jsx("line", { x1: "12", y1: "2", x2: "12", y2: "9" }), _jsx("line", { x1: "4.22", y1: "10.22", x2: "5.64", y2: "11.64" }), _jsx("line", { x1: "1", y1: "18", x2: "3", y2: "18" }), _jsx("line", { x1: "21", y1: "18", x2: "23", y2: "18" }), _jsx("line", { x1: "18.36", y1: "11.64", x2: "19.78", y2: "10.22" }), _jsx("line", { x1: "23", y1: "22", x2: "1", y2: "22" }), _jsx("polyline", { points: "8 6 12 2 16 6" })] })),
    sunset: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M17 18a5 5 0 0 0-10 0" }), _jsx("line", { x1: "12", y1: "9", x2: "12", y2: "2" }), _jsx("line", { x1: "4.22", y1: "10.22", x2: "5.64", y2: "11.64" }), _jsx("line", { x1: "1", y1: "18", x2: "3", y2: "18" }), _jsx("line", { x1: "21", y1: "18", x2: "23", y2: "18" }), _jsx("line", { x1: "18.36", y1: "11.64", x2: "19.78", y2: "10.22" }), _jsx("line", { x1: "23", y1: "22", x2: "1", y2: "22" }), _jsx("polyline", { points: "16 5 12 9 8 5" })] })),
    tag: (_jsxs(_Fragment, { children: [_jsx("path", { d: "M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z" }), _jsx("line", { x1: "7", y1: "7", x2: "7.01", y2: "7" })] })),
    thermometer: (_jsx("path", { d: "M14 14.76V3.5a2.5 2.5 0 0 0-5 0v11.26a4.5 4.5 0 1 0 5 0z" })),
    trash: (_jsxs(_Fragment, { children: [_jsx("polyline", { points: "3 6 5 6 21 6" }), _jsx("path", { d: "M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" }), _jsx("line", { x1: "10", y1: "11", x2: "10", y2: "17" }), _jsx("line", { x1: "14", y1: "11", x2: "14", y2: "17" })] })),
    up: _jsx("polyline", { points: "18 15 12 9 6 15" }),
    weight: (_jsxs(_Fragment, { children: [_jsx("path", { stroke: "none", d: "M0 0h24v24H0z", fill: "none" }), _jsx("path", { d: "M12 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" }), _jsx("path", { d: "M6.835 9h10.33a1 1 0 0 1 .984 .821l1.637 9a1 1 0 0 1 -.984 1.179h-13.604a1 1 0 0 1 -.984 -1.179l1.637 -9a1 1 0 0 1 .984 -.821z" })] })),
    wind: (_jsx("path", { d: "M9.59 4.59A2 2 0 1 1 11 8H2m10.59 11.41A2 2 0 1 0 14 16H2m15.73-8.27A2.5 2.5 0 1 1 19.5 12H2" })),
};
export default function Icon({ draw, margin, name, size = "inherit", ...rest }) {
    const className = `icon icon--size-${size}${draw ? " icon--draw" : ""}${size === "inherit" ? " icon--inline" : ""}${margin ? ` icon--margin-${margin}` : ""}`;
    return (_jsx("svg", { strokeWidth: "2", ...rest, className: className, fill: "none", stroke: "currentColor", strokeLinecap: "round", strokeLinejoin: "round", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", children: iconNameMap[name] }));
}
