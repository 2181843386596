import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from "../Icon";
const maxPageButtons = 5;
const halfMaxPageButtons = Math.floor(maxPageButtons / 2);
export default function Pagination({ page, pageCount, onChange }) {
    const pageNumbers = [];
    const pageButtonCount = Math.min(pageCount, maxPageButtons);
    for (let i = 0; i < pageButtonCount; i++) {
        if (page <= halfMaxPageButtons) {
            pageNumbers.push(i);
        }
        else if (page >= pageCount - halfMaxPageButtons) {
            pageNumbers.push(i + pageCount - Math.min(maxPageButtons, pageCount));
        }
        else {
            pageNumbers.push(i + page - halfMaxPageButtons);
        }
    }
    return pageCount > 1 ? (_jsxs("div", { className: "pagination select-none", children: [page !== 0 && (_jsx("button", { "aria-label": "navigate to previous page", className: "pagination__button pagination__button--arrow br-1 ripple", "data-page": page - 1, onClick: () => onChange(page - 1), type: "button", children: _jsx(Icon, { name: "left", size: "2" }) })), pageNumbers.map((i) => (_jsx("button", { "aria-label": `navigate to page ${i + 1}`, className: "pagination__button br-1", "data-page": i, disabled: i === page, onClick: () => onChange(i), children: i + 1 }, i))), page !== pageCount - 1 && (_jsx("button", { "aria-label": "navigate to next page", className: "pagination__button pagination__button--arrow br-1 ripple", "data-page": page + 1, onClick: () => onChange(page + 1), type: "button", children: _jsx(Icon, { name: "right", size: "2" }) }))] })) : null;
}
