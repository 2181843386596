import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Field from "../../privateComponents/Field";
import FieldError from "../../privateComponents/FieldError";
import FieldLabelText from "../../privateComponents/FieldLabelText";
import Icon from "../Icon";
import Label from "../../privateComponents/Label";
import SupportiveText from "../../privateComponents/SupportiveText";
export default function Select({ error, label, optional = false, supportiveText, ...rest }) {
    return (_jsxs(Field, { children: [_jsxs(Label, { children: [_jsx(FieldLabelText, { optional: optional, children: label }), _jsx(SupportiveText, { children: supportiveText }), _jsxs("span", { className: "select__select-container", children: [_jsx("select", { 
                                // When fields aren't in forms they should not be required
                                // and do not need to be marked explicitly as optional,
                                // hence the user can override this attribute
                                required: !optional, ...rest, "aria-invalid": Boolean(error), className: "select__select" }), _jsx("span", { className: "select__icon", children: _jsx(Icon, { name: "down", size: "2" }) })] })] }), _jsx(FieldError, { children: error })] }));
}
