import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Button, Paper, TextField } from "../..";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { requiredValidator, validateEmailField, validatePasswordField, } from "../../utils/validators";
export default function ResetPasswordPage({ onSubmit, ...rest }) {
    const navigate = useNavigate();
    const [codeError, setCodeError] = React.useState("");
    const [emailError, setEmailError] = React.useState("");
    const [passwordError, setPasswordError] = React.useState("");
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [submitError, setSubmitError] = React.useState();
    const location = useLocation();
    const defaultEmailAddress = new URLSearchParams(location.search).get("email");
    const hasDefaultEmailAddress = Boolean(defaultEmailAddress);
    return (_jsx(Paper.Group, { ...rest, children: _jsxs(Paper, { children: [_jsx("h2", { children: "Reset password" }), _jsxs("p", { children: ["Check your email for your verification code (it may be in your junk folder). If you don't have an account you will need to", " ", _jsx(Link, { to: "/sign-up", children: "sign up" }), "."] }), _jsxs("form", { noValidate: true, onSubmit: async (e) => {
                        e.preventDefault();
                        setSubmitError(undefined);
                        const code = e.target.code.value;
                        const email = e.target.email.value;
                        const password = e.target.password.value;
                        const codeErrorMessage = requiredValidator(code);
                        const emailErrorMessage = validateEmailField(email);
                        const passwordErrorMessage = validatePasswordField(password);
                        if (codeErrorMessage || emailErrorMessage || passwordErrorMessage) {
                            setCodeError(codeErrorMessage || "");
                            setEmailError(emailErrorMessage || "");
                            setPasswordError(passwordErrorMessage || "");
                            return;
                        }
                        if (codeError)
                            setCodeError("");
                        if (emailError)
                            setEmailError("");
                        if (passwordError)
                            setPasswordError("");
                        setIsSubmitting(true);
                        try {
                            await onSubmit({ code, email, password, setSubmitError });
                            const searchParams = new URLSearchParams();
                            searchParams.set("password-reset", "true");
                            searchParams.set("email", email);
                            navigate(`/sign-in?${searchParams}`);
                        }
                        finally {
                            setIsSubmitting(false);
                        }
                    }, children: [_jsx(TextField, { autoComplete: "email", 
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus: !hasDefaultEmailAddress, defaultValue: defaultEmailAddress || undefined, error: emailError, label: "Email", name: "email", supportiveText: "Take care to type your address correctly", type: "email" }), _jsx(TextField
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        , { 
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus: hasDefaultEmailAddress, error: codeError, label: "Verification code", name: "code", type: "code" }), _jsx(TextField, { autoComplete: "new-password", error: passwordError, label: "Password", name: "password", type: "password" }), submitError && (_jsx("p", { className: "center", children: _jsx("small", { className: "negative", children: submitError }) })), _jsx(Button.Group, { children: _jsx(Button, { disabled: isSubmitting, children: "Reset password" }) }), _jsx("p", { className: "center", children: _jsxs("small", { children: ["Haven't received a verification code?", " ", _jsx(Link, { to: "/forgot-password", children: "Try again" }), "."] }) })] })] }) }));
}
