import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import Field from "../../privateComponents/Field";
import FieldError from "../../privateComponents/FieldError";
import FieldLabelText from "../../privateComponents/FieldLabelText";
import Label from "../../privateComponents/Label";
import SupportiveText from "../../privateComponents/SupportiveText";
const handleLoad = ({ target: { src } }) => URL.revokeObjectURL(src);
export default function ImageUpload({ error, label, onChange, optional = false, supportiveText, ...rest }) {
    const [{ imageName, imageUrl }, setState] = React.useState({
        imageName: undefined,
        imageUrl: undefined,
    });
    const handleChange = (e) => {
        const files = e.target.files;
        if (!files.length) {
            setState({ imageName: undefined, imageUrl: undefined });
        }
        else {
            const file = files[0];
            setState({
                imageName: file.name,
                imageUrl: URL.createObjectURL(file),
            });
        }
        if (onChange)
            onChange(e);
    };
    return (_jsxs(Field, { children: [_jsxs(Label, { children: [_jsx(FieldLabelText, { optional: optional, children: label }), _jsx(SupportiveText, { children: supportiveText }), imageUrl && (_jsx("img", { alt: `preview of ${imageName}`, className: "image-upload__img bw-2", onLoad: handleLoad, src: imageUrl })), _jsx("input", { 
                        // When fields aren't in forms they should not be required
                        // and do not need to be marked explicitly as optional,
                        // hence the user can override this attribute
                        required: !optional, ...rest, accept: "image/*", "aria-invalid": Boolean(error), className: "image-upload__input", onChange: handleChange, type: "file" })] }), _jsx(FieldError, { children: error })] }));
}
