import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Button, Paper, TextField } from "../..";
import { Link, useLocation } from "react-router-dom";
import { validateEmailField, validatePasswordField, } from "../../utils/validators";
export default function SignInPage({ onSubmit, ...rest }) {
    const [emailError, setEmailError] = React.useState("");
    const [passwordError, setPasswordError] = React.useState("");
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [submitError, setSubmitError] = React.useState();
    const location = useLocation();
    const defaultEmailAddress = new URLSearchParams(location.search).get("email");
    const hasDefaultEmailAddress = Boolean(defaultEmailAddress);
    const passwordWasReset = new URLSearchParams(location.search).has("password-reset");
    return (_jsx(Paper.Group, { ...rest, children: _jsxs(Paper, { children: [_jsx("h2", { children: "Sign in" }), passwordWasReset && (_jsx("p", { children: _jsx("small", { className: "positive", children: "Password successfully reset, sign in with your new password!" }) })), _jsxs("form", { noValidate: true, onSubmit: async (e) => {
                        e.preventDefault();
                        setSubmitError(undefined);
                        const email = e.target.email.value;
                        const password = e.target.password.value;
                        const emailErrorMessage = validateEmailField(email);
                        const passwordErrorMessage = validatePasswordField(password);
                        if (emailErrorMessage || passwordErrorMessage) {
                            setEmailError(emailErrorMessage || "");
                            setPasswordError(passwordErrorMessage || "");
                            return;
                        }
                        if (emailError)
                            setEmailError("");
                        if (passwordError)
                            setPasswordError("");
                        setIsSubmitting(true);
                        try {
                            await onSubmit({ email, password, setSubmitError });
                        }
                        finally {
                            setIsSubmitting(false);
                        }
                    }, children: [_jsx(TextField, { autoComplete: "email", 
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus: !hasDefaultEmailAddress, defaultValue: defaultEmailAddress || undefined, error: emailError, label: "Email", name: "email", type: "email" }), _jsx(TextField, { autoComplete: "current-password", 
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus: hasDefaultEmailAddress, error: passwordError, label: "Password", name: "password", type: "password" }), submitError && (_jsx("p", { className: "center", children: _jsx("small", { className: "negative", children: submitError }) })), _jsx(Button.Group, { children: _jsx(Button, { disabled: isSubmitting, children: "Sign in" }) }), _jsxs("p", { className: "center", children: [_jsxs("small", { children: ["Don't have an account? ", _jsx(Link, { to: "/sign-up", children: "Sign up" }), "!"] }), _jsx("br", {}), _jsxs("small", { children: [_jsx(Link, { to: "/forgot-password", children: "Forgotten your password" }), "?"] })] })] })] }) }));
}
