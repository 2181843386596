import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from "react";
import WordCloudPrimary from "./WordCloudPrimary";
import WordCloudSecondary from "./WordCloudSecondary";
export default memo(function WordCloud({ ...props }) {
    if (!Object.keys(props.words).length)
        return;
    // TODO delete WordCloudSecondary when/if Safari ever supports OffscreenCanvas
    return "OffscreenCanvas" in window ? (_jsx(WordCloudPrimary, { ...props })) : (_jsx(WordCloudSecondary, { ...props }));
});
