import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as ReactDOM from "react-dom";
import { useCallback, useEffect, } from "react";
import { getCssTime1 } from "../../utils/getCssVar";
import useTransition from "react-transition-state";
export default function Mask({ onClose, open, portalContainer, ...rest }) {
    const handleKeyDown = useCallback((e) => {
        if (open && onClose && e.code === "Escape")
            onClose();
    }, [onClose, open]);
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);
    const [transitionState, toggle] = useTransition({
        initialEntered: open,
        mountOnEnter: true,
        preEnter: true,
        timeout: getCssTime1() + 100,
        unmountOnExit: true,
    });
    useEffect(() => toggle(open), [open]);
    if (!portalContainer)
        return;
    return ReactDOM.createPortal(
    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    _jsxs("div", { onKeyDown: handleKeyDown, children: [transitionState.isMounted && (_jsx("div", { className: `mask__mask z-3 mask__mask--${transitionState.status}`, onClick: onClose })), _jsx("div", { ...rest, className: "mask__container z-3" })] }), 
    /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    portalContainer);
}
