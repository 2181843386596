import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Field from "../../privateComponents/Field";
import FieldError from "../../privateComponents/FieldError";
import FieldLabelText from "../../privateComponents/FieldLabelText";
import Label from "../../privateComponents/Label";
import SupportiveText from "../../privateComponents/SupportiveText";
export default function TextArea({ label, error, optional = false, supportiveText, ...rest }) {
    return (_jsxs(Field, { children: [_jsxs(Label, { children: [_jsx(FieldLabelText, { optional: optional, children: label }), _jsx(SupportiveText, { children: supportiveText }), _jsx("textarea", { 
                        // When fields aren't in forms they should not be required
                        // and do not need to be marked explicitly as optional,
                        // hence the user can override this attribute
                        required: !optional, ...rest, "aria-invalid": Boolean(error) })] }), _jsx(FieldError, { children: error })] }));
}
