import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Button, Paper, TextField } from "../..";
import { validatePasswordField } from "../../utils/validators";
export default function ChangePasswordPage({ onSubmit, ...rest }) {
    const [currentPasswordError, setCurrentPasswordError] = React.useState("");
    const [newPasswordError, setNewPasswordError] = React.useState("");
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [submitError, setSubmitError] = React.useState();
    const [successfullyChanged, setSuccessfullyChanged] = React.useState(false);
    return (_jsx(Paper.Group, { ...rest, children: _jsxs(Paper, { children: [_jsx("h2", { children: "Change password" }), successfullyChanged ? (_jsxs(_Fragment, { children: [_jsx("p", { className: "positive", children: "Password successfully changed!" }), _jsx("p", { children: "Use your new password next time you sign in." })] })) : (_jsxs("form", { noValidate: true, onSubmit: async (e) => {
                        e.preventDefault();
                        setSubmitError(undefined);
                        const currentPassword = e.target
                            .currentPassword.value;
                        const newPassword = e.target.newPassword
                            .value;
                        const currentPasswordErrorMessage = validatePasswordField(currentPassword);
                        const newPasswordErrorMessage = validatePasswordField(newPassword);
                        if (currentPasswordErrorMessage || newPasswordErrorMessage) {
                            setCurrentPasswordError(currentPasswordErrorMessage || "");
                            setNewPasswordError(newPasswordErrorMessage || "");
                            return;
                        }
                        if (currentPasswordError)
                            setCurrentPasswordError("");
                        if (newPasswordError)
                            setNewPasswordError("");
                        setIsSubmitting(true);
                        try {
                            await onSubmit({
                                currentPassword,
                                newPassword,
                                setSubmitError,
                            });
                            setSuccessfullyChanged(true);
                        }
                        finally {
                            setIsSubmitting(false);
                        }
                    }, children: [_jsx(TextField, { autoComplete: "current-password", 
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus: true, error: currentPasswordError, label: "Current password", name: "currentPassword", type: "password" }), _jsx(TextField, { autoComplete: "new-password", error: newPasswordError, label: "New password", name: "newPassword", type: "password" }), submitError && (_jsx("p", { className: "center", children: _jsx("small", { className: "negative", children: submitError }) })), _jsx(Button.Group, { children: _jsx(Button, { disabled: isSubmitting, children: "Change password" }) })] }))] }) }));
}
