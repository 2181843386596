import { useEffect, useState } from "react";
const mediaQuery = typeof window === "undefined"
    ? undefined
    : window.matchMedia("(min-width: 66rem)");
export default function useIsWideResolution() {
    const [isWideResolution, setIsWideResolution] = useState(mediaQuery ? mediaQuery.matches : false);
    useEffect(() => {
        const mediaQueryHandler = (e) => setIsWideResolution(e.matches);
        mediaQuery?.addEventListener("change", mediaQueryHandler);
        return () => mediaQuery?.removeEventListener("change", mediaQueryHandler);
    }, []);
    return isWideResolution;
}
